// protocol entry mock
.protocol-entry-sidebar-menu {
  ul {
    margin: 0;
    padding: 0;

    li {
      padding: 5px 0;
      cursor: pointer;
      font-size: 12px;
      color: #545a6d;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 10px;

      .protocol-entry-badge {
        display: flex;
        padding: 2px;
        background-color: #c9e9f7;
        width: 32px;
        height: 24px;
        border-radius: 2px;
        text-align: center;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        color: #5a8193;
      }

      &.clicked {
        color: #2770ec;

        .protocol-entry-badge {
          background-color: #2770ec;
          color: #ffffff;
        }
      }
    }

  }
}

.protocol-entry-legend {
  background: linear-gradient(transparent, #fff);
  display: table;
  position: absolute;
  font-weight: 500;
  padding: 2px 4px;
  top: -15px;
  font-size: 1rem;
}

.protocol-entry-tab-wrapper {
  display: grid;
  grid-template-columns: 280px auto;
  gap: 30px;

  .protocol-entry-tab-content {
    &.card {
      margin-bottom: 0;
    }
  }

  .protocol-entry-tab-navigator {
    display: flex;
    grid-column: span 2;
    justify-content: space-between;
    padding-left: 310px;
    margin-bottom: 50px;

    .btn {
      width: 160px;
    }
  }
}

.dissertation-entry-tab-wrapper {
  display: grid;
  grid-template-columns: 280px auto;
  gap: 30px;
}

.header-basic-info {
  font-size: smaller;
  margin-bottom: 7px;
}

.header-basic-info-title {
  font-size: medium;
  font-weight: bold;
  margin-bottom: 1px;
}

.blue-box {
  width: 100%;
  border: 1px solid #1ba0b1;
  padding: 3px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

//.content-header {
//  margin-left: 3%;
//}
//
//.content-card {
//  margin-left: 3%;
//}

.textarea-protocol-entry {
  min-height: calc(1.5em + 0.94rem + 400px) !important;
}

.relevant {
  margin-left: 15px;
}

.faculty-input {
  margin-left: 22px;
  padding: 2px;
  margin-bottom: 5px;
  padding-left: 5px;
}

.discipline-input {
  padding: 2px;
  margin-left: 7px;
  padding-right: 14px;
  padding-left: 5px;
}


// tab
.nav-tabs {
  border-bottom: 1px solid #e9e9ef;

  .nav-item {
    margin-right: 4px;
  }

  .nav-link {
    margin-bottom: -1px;
    color: #261f33;
    border: 1px solid transparent;
    background-color: #8a929721;
    border-radius: 4px 4px 0 0;
    cursor: pointer;
  }
}


.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #ffffff;
  // background-color: #3b9fe3f5;
  background-color: #0091e8;
  border-color: #ced4da #ced4da #fff;
}

.tab-title {
  background-color: #e7f1fd;
}

.tab-title-text {
  padding-top: 10px;
  margin-left: 7px;
  margin-bottom: 10px;
  color: #4569dc;
}


// attachment

.button-tip {
  padding: 0.3rem;
  margin: 1.25rem;
  font-size: 12px;
  border: 1px solid #806f6f;
}

.button-attachmentAdd {
  margin-top: 1.8rem !important;
}

.button-name-document {
  margin-top: 1.8rem;
}

// signature
.signature {
  margin-left: 3rem;
  margin-top: 2rem;
}

.signature-date {
  margin-top: 4rem;
}

.signature-font-p {
  font-size: 12px;
}

.agree-button {
  margin-left: 15px;
  padding: 1px 5px 1px 5px;
  font-size: 12px;
  height: 25px;
  width: 80px;
}

// guide signature
.vertical-line {
  width: 1px; /* Adjust the width of the line as needed */
  height: 100%; /* Makes the line span the entire height of its container */
  background-color: #000; /* Change the color of the line as needed */
  position: absolute; /* Position the line */
  left: 50%; /* Position it in the middle of the container */
  transform: translateX(-50%); /* Center it exactly in the middle */
  --bs-gutter-x: 0px !important;
}

.horizontal-line {
  border: none; /* Remove the default border */
  border-top: 1px solid #000; /* Add a 1px solid black top border for the horizontal line */
  margin: 10px 0; /* Adjust the margin as needed to control spacing */
}

.guide-signature {
  margin-top: 15px;
  padding-left: 20px;
}

.co-guide-signature {
  margin: 5px 0px 15px 50px;

}

.signature-header-box {
  border: 1px solid black;
  padding: 10px 0px 0px 0px;
}

.not-approved {
  color: #e19f25;
  margin: 5px 0px 0px 60px;
}

// footer
.button-container {
  display: flex;
  justify-content: space-between; /* This will position the buttons at the start and end of the container */
  padding: 5px; /* Add padding as needed */
}

.start-button {
  padding: 10px 20px;
}

.end-button {
  padding: 10px 20px;
}


// reusable styles classes
.padding-right-0 {
  padding-right: 0px;
}

.padding-left-0 {
  padding-left: 0px;
}

.padding-x-0 {
  padding-right: 0px;
  padding-left: 0px;
}

.padding-y-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.input-field-group {
  display: flex;
  align-self: center;

  & > div {
    flex: 0 0 calc(100% - 100px);
  }
}
