// 
// authentication.scss
//
.auth-page {}

.auth-bg {
    background-color: #f1f1f1;
    background-image: url("../../../images/auth-bg.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .bg-overlay {
        opacity: 0.7;
    }

    @media (min-width: 768px) {
        height: 100vh;
    }
}

.auth-page-bg {
    background: linear-gradient(to right, #9bf39b, #8ff0f3);
}

.auth-page-card {
    background: rgba(255, 255, 255, 0.8);
}

.auth-full-page-content {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

// auth 2

.auth-logo {
    .logo-txt {
        color: $dark;
        font-size: 20px;
    }
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
    input[type="input"]+.btn .mdi-eye-outline {
        &:before {
            content: "\F06D1";
        }
    }
}

// for bubble effect
.bg-bubbles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    li {
        position: absolute;
        list-style: none;
        display: block;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        background-color: rgba(255, 255, 255, 0.1);
        bottom: -50px;
        animation: square 20s infinite;
        transition-timing-function: linear;

        &:nth-child(1) {
            left: 10%;
        }

        &:nth-child(2) {
            left: 20%;
            width: 120px;
            height: 120px;
            animation-delay: 2s;
            animation-duration: 17s;
        }

        &:nth-child(3) {
            left: 25%;
            animation-delay: 4s;
        }

        &:nth-child(4) {
            left: 40%;
            width: 80px;
            height: 80px;
            animation-duration: 22s;
        }

        &:nth-child(5) {
            left: 70%;
            width: 90px;
            height: 90px;
        }

        &:nth-child(6) {
            left: 70%;
            width: 120px;
            height: 120px;
            animation-delay: 3s;
        }

        &:nth-child(7) {
            left: 32%;
            width: 150px;
            height: 150px;
            animation-delay: 7s;
        }

        &:nth-child(8) {
            left: 55%;
            width: 80px;
            height: 80px;
            animation-delay: 15s;
            animation-duration: 40s;
        }

        &:nth-child(9) {
            left: 25%;
            width: 50px;
            height: 50px;
            animation-delay: 2s;
            animation-duration: 40s;
        }

        &:nth-child(10) {
            left: 90%;
            width: 140px;
            height: 140px;
            animation-delay: 11s;
        }
    }
}

@keyframes square {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-1000px) rotate(600deg);
    }
}

body[data-layout-mode="dark"] {
    .auth-logo {
        .logo-txt {
            color: $white;
        }
    }
}

.auth-page-header {
    max-width: 425px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    &>div {
        &:first-child {
            margin-right: 20px;
        }
    }

    img {
        max-width: 90px;
        height: auto;
    }

    h4 {
        margin: 25px 0;
    }
}