.thesis-basic-info {
  display: grid;
  grid-template-columns:repeat(3, 1fr);
  gap: 10px;

  & > div {
    padding: 8px;
    background-color: #f2fbff;
    border: 1px solid #e1f4ff;

    &.thesis-title-col {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }

  ol {
    margin: 0;
    padding-left: 20px;

    & > li {

      ol {
        margin: 0 0 0 10px;
        padding: 0;
        gap: 5px;

        li {
        }
      }
    }
  }
}

.dissertation-entry-sidebar-menu {
  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      padding: 5px 0;
      cursor: pointer;
      font-size: 12px;
      color: #545a6d;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 10px;

      .thesis-entry-badge {
        display: flex;
        padding: 2px;
        background-color: #c9e9f7;
        width: 32px;
        height: 24px;
        border-radius: 2px;
        text-align: center;
        font-size: 12px;
        align-items: center;
        justify-content: center;
        color: #5a8193;
        //text-transform: uppercase;
      }

      i {
        margin-left: auto;
      }

      &.active {
        color: #2770ec;

        .thesis-entry-badge {
          background-color: #2770ec;
          color: #ffffff;
        }
      }
    }

    ul {
      margin-left: 15px;
      border-left: 2px solid #dbdbdb;
      padding-left: 15px;
    }
  }
}

.dissertation-upload-files-wrapper {
  //padding: 20px 0;
}

.file-view-btn {
  display: inline-flex;
  align-items: center;
  padding: 5px 12px;
  color: #ffffff;
  background-color: #4ba6ef;
  border: 1px solid #4ba6ef;
  gap: 6px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 rgba(39, 112, 236, 0.5);
  font-size: 14px;
  line-height: 14px;

  span {
    //background-color: #100b0b;
  }

  .file-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 180px;
    overflow: hidden;
    direction: rtl;
  }
}

.dissertation-file-field-wrapper {
  display: flex;
  max-width: 500px;
  gap: 30px;
  margin: 30px 0;
  align-items: center;

  & > div {


    &:first-child {
      flex: 0 0 140px;
      max-width: 140px;

      a {
        display: block;
        width: 100%;
        height: 100%;

        .uploaded-file-preview {
          background-color: #eff8ff;
          border: 1px dashed #c9e9f7;
          padding: 2px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 140px;
          border-radius: 4px;
          text-align: center;
        }

        img {
          max-width: 100%;
          height: auto;
          max-height: 100%;
        }

      }

      .file-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
        overflow: hidden;
        direction: rtl;
        color: #085675;
        font-weight: 600;
      }
    }
  }
}

.floating-comments-btn {
  position: fixed;
  right: 0;
}
