.card {
    .card-button-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .btn {
            flex: 0 0 calc(50% - 5px);
            max-width: calc(50% - 5px);
        }
    }
}