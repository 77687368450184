.required-field::after {
  content: '*';
  color: red;
  margin-left: 2px;
}

a.disabled {
  color: #ccc !important;
  pointer-events: none;
}


ul.information-table {
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    display: block;
    padding: 5px;

    strong {
      display: inline-block;
      max-width: 30%;
      min-width: 120px;
    }
  }
}