.large-from-grid,
.large-form-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    .large-form-field,
    .large-form-field {
        grid-column-start: 1;
        grid-column-end: 5;
    }
}

.incremental-from-field-grid,
.incremental-form-field-grid {
    border: 1px solid #e5e5e5;
    background-color: #efefef;
    padding: 20px 10px;
    border-radius: 5px;

    &>div {
        padding: 0 10px;

        &:first-child {
            flex: 0 0 calc(100% - 100px);
            // max-width: calc(100% - 100px);
        }

        &:last-child {
            flex: 0 0 100px;
            max-width: 100px;
        }
    }
}

.image-upload-wrapper {
    margin: 0 !important;
    cursor: pointer;
    position: relative;

    &>div {
        &.image-preview {
            padding: 3px;
            background-color: #fff;
            border: 1px solid #efefef;
            max-width: 120px;
            max-height: 120px;
            overflow: hidden;

            &.image-preview {
                img {
                    max-width: 100%;
                    height: auto;
                }
            }

            &.image-upload {}
        }
    }
}

// .image-upload-wrapper {
//   margin: 0 !important;
//   cursor: pointer;
//   position: relative;

//   &>div {
//     padding: 3px;
//     background-color: #fff;
//     border: 1px dashed #E2E2E2;
//     text-align: center;
//     width: 120px;
//     height: 120px;
//     overflow: hidden;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;

//     &.image-preview {
//       img {
//         max-width: 100%;
//         height: auto;
//       }
//     }

//     &.image-upload {
//       i {
//         font-size: 30px;
//         color: #A8A8A8;
//       }

//       p {
//         font-size: 10px;
//         margin: 0;
//       }
//     }
//   }
// }
.input-group-with-label {
    display: flex;

    &>div {
        flex: 0 0 50%;
        max-width: 50%;

        &:first-child {
            input {
                border-radius: 4px 0 0 4px;
            }
        }

        &:last-child {
            input {
                border-radius: 0 4px 4px 0;
            }
        }
    }
}

form {
    label {
        span {
            color: #fd625e;
        }
    }
}

.password-input {
    position: relative;

    &>div {
        flex: 0 0 calc(100% - 40px);
        max-width: calc(100% - 40px);

        &>div {
            position: absolute;
        }
    }

    &>button {
        flex: 0 0 40px;
        max-width: 40px;
    }
}
.performance-assesment-wrapper{
    display: flex;
    gap: 5px;
    &>div{
        flex:  0 0 30px;
        max-width: 30px;
        &:last-child{
            flex: 0 0 90px;
            max-width: 90px;
            label{
            
                // width: 30px;    
               font-size: 12px;
                border-radius: 4px;
              
            }
        
        }
    }
    label{
        display: flex;
        // width: 30px;    
        height: 30px;
        background-color: #d8d8d8;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        cursor: pointer;
        margin-bottom: 0;
        position: relative;
        span{
            position: absolute;
            display: none;
            background: #4ba6ef;
            border: 1px solid #ccc;
            color: #ffffff;
            bottom: 100%;
            font-size: 10px;
            padding: 5px 15px;
            z-index: 3;
            border-radius: 2px;
            &::after{
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                background-color: #4ba6ef;
                position: absolute;
                transform: translateX(-50%) rotate(45deg);
                left: 50%;

            }
        }
        &:hover{
            span{
                display: block;
            }
        }
    }

    input[type="radio"]{
        display: none;   
        &:checked{
          &~label{
            background-color: #2770ec; 
            color: #fff;
            &.Unsatisfactory{
                background-color: #8B0000;  
            }
            &.Satisfactory{
                background-color: #FFA500;  
            }
            &.Superior{
                background-color: #006400;  
            }
          } 
        }
    }
}
.performance-assesment-indicator-wrapper{
    &>div{
        padding: 3px 10px;
        transition: 0.2s;
        border-left: 2px solid transparent;
        &:nth-child(odd){
            background-color: #f8f8f8;
        }
        &:hover{
            background-color: #f1f1f1;
            border-color: #2770ec;
        }
    }
}
.performance-assesment-indicator{
    display: grid;
    grid-template-columns:280px 408px auto;
    gap: 10px 20px;
    align-items: center;
    &>div{
        &:nth-child(1){
            font-weight: 500;
        }
    }
}