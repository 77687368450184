.block-heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    border-bottom: 1px solid #D9D9D9;
    padding-bottom: 10px;
    position: relative;

    &::before {
        content: "";
        display: block;
        max-width: 20%;
        min-width: 50px;
        background-color: #265ED6;
        height: 3px;
        position: absolute;
        bottom: -2px;
    }
}

.div-heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 550;
    border-bottom: 1px solid #D9D9D9;
    padding: 5px;
    text-align: center;
    background-color: #799dec;
    margin-bottom: 10px;
    border-radius: 0.25rem !important;
}

.delete-font {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
}