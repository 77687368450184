// react-data-table-component style update
.rdt_Table {
  .rdt_TableHeadRow {
    background-color: #f8faff;
    //min-height: auto !important;
    //padding-top: 5px;
    //padding-bottom: 5px;
  }

  .rdt_TableCol {
    font-weight: bold;
    font-size: 14px;
  }

  .rdt_TableBody {
    //border: 1px solid rgba(0, 0, 0, 0.12);
  }

  .rdt_TableRow {
    min-height: auto !important;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .eoCEVt:hover {
    background-color: #fafafa;
  }

  .btn-sm {
    min-width: 65px;
  }
}

.regular-datatable-view {
  .rdt_Table {
    .rdt_TableRow {
      min-height: auto !important;
      padding-top: 12px;
      padding-bottom: 12px;
    }

  }
}
