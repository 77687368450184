.main-content {
  background: #fbfbfb;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(136, 131, 131, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.error-size {
  font-size: 12px;
}

.justified-text {
  text-align: justify;
}

.text-wrap {
  white-space: normal;
}

//.dashboard-user-alert-modal {
//  max-width: 600px;
//}

.dashboard-user-alert {
  display: flex;
  align-items: center;
  //width: 600px;
  //background-color: #cccccc;
  margin: 10px auto;
  position: relative;


  .dashboard-user-alert-thumb {
    flex: 0 0 100px;
    max-height: 100px;
    height: 100px;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    z-index: 3;
    box-shadow: 0 0 0 5px #fff;
    background: #d9d9d9 url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none"><path d="M79.1666 87.5V79.1667C79.1666 74.7464 77.4107 70.5072 74.2851 67.3816C71.1595 64.256 66.9203 62.5 62.5 62.5H37.5C33.0797 62.5 28.8405 64.256 25.7149 67.3816C22.5893 70.5072 20.8333 74.7464 20.8333 79.1667V87.5" stroke="%23B8B8B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M50 45.8333C59.2047 45.8333 66.6666 38.3714 66.6666 29.1667C66.6666 19.9619 59.2047 12.5 50 12.5C40.7952 12.5 33.3333 19.9619 33.3333 29.1667C33.3333 38.3714 40.7952 45.8333 50 45.8333Z" stroke="%23B8B8B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>') no-repeat center;
    background-size: cover;

    img {
      max-width: 100%;
      height: auto;
      transform: scale(1.3);
    }
  }

  .dashboard-user-alert-info {
    flex: 0 0 calc(100% - 90px);
    max-height: calc(100% - 90px);

    & > div {
      display: table;
      padding: 5px 20px 5px 50px;
      transform: translateX(-30px);
    }

    .dashboard-user-alert-title {
      background-color: #FF4545;
      color: #ffffff;
      font-weight: 700;
      border-radius: 0 4px 4px 0;
    }

    .dashboard-user-alert-message {
      background-color: #fff;
      border-radius: 0 4px 4px 0;
      border: 1px solid #f5f5f5;
    }
  }

}

.dashboard-user-alert-modal {
  .modal-content {
    background-color: transparent !important;
    border: none;

    .dashboard-user-alert-close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 3px;
      background-color: #FF4545;
      border-radius: 30px;
      width: 32px;
      height: 32px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 5;

      svg {
        width: 16px;
        height: 16px;
      }

      path {
        fill: white;
      }

      &:hover {
        background-color: #ff1e1e;
      }
    }
  }
}

.modal {
  &.fade {
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
  }
}

.modal-backdrop.show {
  opacity: 0.7;
}


.page-breadcrumb-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 20px;
  align-items: center;

  .breadcrumb {
    margin-bottom: 0;
    padding: 0;
  }
}

.training-table {
  .advance-training {
    background-color: #f3fcff;
  }

  .specialty-training {
    background-color: #f7fff8;
  }
}

.competency-search-form {
  max-width: 300px;
}

.collapse-wrapper {


  .collapse {
    padding: 8px 15px;
    background-color: #fbfbfb;
    border: 1px solid #eeeeee;
  }

  .collapse-title {
    padding: 10px 15px;
    border: 1px solid #eeeeee;
    cursor: pointer;
    background-color: #f8fff9;
    font-weight: 500;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    input[type='checkbox'] {
      margin-right: 10px;
    }
  }
}
.table-secondary {
  --bs-table-bg: #f8faff;
  --bs-table-striped-bg: #d8d9dc;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cccdd1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d2d3d7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cccdd1;
}


.not-found-container {
  background: linear-gradient(45deg, #d0e0f4, #69f1ab);
}

.server-error-container {
  background: linear-gradient(45deg, #eeb6b6, #f56262);
}

.display-1 {
  font-size: 10rem;
  font-weight: bold;
  color: #343a40;
}

.lead {
  font-size: 1.5rem;
  margin-bottom: 30px;
}

.animation img {
  width: 160px;
  max-width: 160px;
  margin-bottom: 20px;
}
.custom-modal {
  .modal-content {
    border-radius: 5px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  }

  .custom-modal-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 10px;
    i {
      font-size: 70px;
      color: #fa2036;
    }
  }

  .custom-modal-body {
    padding: 2rem;
    .modal-question {
      font-size: 24px;
      font-weight: 700;
    }
    p {
      font-size: 16px;
      color: #6c757d;
    }
  }

  .custom-modal-footer {
    padding: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
    border-top: none;

    .btn-lg {
      padding: 0.75rem 2rem;
      border-radius: 50px;
      font-size: 18px;
      font-weight: 600;
      display: flex;
      align-items: center;
    }

    .custom-delete-btn {
      background-color: #dc3545;
      border-color: #dc3545;
      color: #fff;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #c82333;
        border-color: #bd2130;
      }
    }

    .custom-cancel-btn {
      background-color: #6c757d;
      border-color: #6c757d;
      color: #fff;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #5a6268;
        border-color: #545b62;
      }
    }
  }
}

.table-flow {
  max-height: 400px; 
  overflow-y: auto; 
  overflow-x: hidden;
}

.profile-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.profile-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}