.filter-wrapper {
  display: flex;
  align-items: flex-end;
  // border-bottom: 1px solid #e4e4e4;
  flex-wrap: wrap;
  gap: 0 20px;

  & > div {
    flex: 0 0 160px;
    max-width: 160px;

    &.extent-field {
      flex: 0 0 200px;
      max-width: 200px;
    }

    &.btn-column {
      flex: 0 0 300px;
      max-width: 300px;
      display: flex;
      gap: 20px;

      button {
        margin-top: 5px;
        width: 100%;
        font-weight: 600;
      }
    }

    label {
      margin-bottom: 5px;
      font-weight: 500;
      color: #000000;
    }
  }
}

.btn-separator {
  flex: 0 0 300px;
  max-width: 300px;
  display: flex;
  padding-left: 0;
  padding-right: 0;

  button {
    margin: 5px 10px 0;
    width: 100%;
    font-weight: 600;
  }
}
