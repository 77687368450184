$draftColor: #499bbb;
$pendingColor: #F97316;
$correctionColor: #8000FF;
$rejectColor: #DC2626;
$approveColor: #16A34A;
$absentColor: #EC4899;
$appliedColor: #0000FF;
$attendedColor: #86EFAC;

.badge {
    &.badge-draft {
        color: $draftColor;
        background-color: lighten($draftColor, 42%);
    }

    &.badge-pending {
        color: $pendingColor;
        background-color: lighten($pendingColor, 42%);
    }

    &.badge-correction {
        color: $correctionColor;
        background-color: lighten($correctionColor, 42%);
    }

    &.badge-rejected {
        color: $rejectColor;
        background-color: lighten($rejectColor, 42%);
    }

    &.badge-approved {
        color: $approveColor;
        background-color: lighten($approveColor, 55%);
    }

    &.badge-absent {
        color: $absentColor;
        background-color: lighten($absentColor, 42%);
    }

    &.badge-applied {
        color: $appliedColor;
        background-color: lighten($appliedColor, 42%);
    }

    &.badge-attended {
        color: $attendedColor;
        background-color: lighten($attendedColor, 42%);
    }
}
